/*
/*
 * This implements the header styles that were changed in 2021 for ICLR.4. They are modified from
 * CSS provided by Andrew Flett (who is another supplier to ICLR).
 */

/* General Styles */

h1,h2,h3,h4,h5,h6 {
  font-family: 'Raleway', sans-serif;
}

.iclr_components {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'kern';
  color: #1A202C;
  background-color: white;
  font-family: 'Lato', sans-serif;

  a.navbar-brand {
    margin-left: 0px;
    margin-right: 0px;
    width: 95px;
    padding: 0;
  }

  .container-fluid {
    padding: 0;
    line-height: 21px;
  }

  .iclr_secondary_navigation {
    z-index: 9;
    height: 75px;
  }

  .iclr_secondary_navigation .container-fluid .navbar-brand {
    line-height: 30px;
  }

  nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }

  .secondaryNavbar.collapse:not(.show) .dropdown-divider {
    visibility: hidden;
    display: none;
  }

  .secondaryNavbar.collapse:not(.show) #indexCardDisplayNavButtons::after {
    content: "";
    background-color: #E6E7E8;
    width: 1px;
  }

  .secondaryNavbar.collapse:not(.show) #legislationDisplayNavButtons::after {
    content: "";
    background-color: #E6E7E8;
    width: 1px;
  }
}

/* Genie */

.genie {
  max-width: 80%;
  margin: 0 auto;
  background: white;

  p {
    font-size: 16px;
  }
}

/* Genie logo */

.genie_logo {
  text-align: center;
}

.genie_logo h1 {
  font-weight: 700;
  font-size: 30px;
}

.genie_logo .genie_logo_icon {
  line-height: 1;
  margin-block: 1.5rem 0.4rem;

  > svg {
    fill: $basic-icon-color;
  }
}


/* Genie features */

.genie_interface {
  padding-top: 4rem;
}

.genie_features .genie_features_list {
  padding-right: 3.2rem;
  padding-left: 0;
  margin: 0;
}

.genie_features .genie_features_list li {
  list-style-type: none;
  margin-bottom: 2.8rem;
  padding: 0;
}

.genie_features .genie_features_list li h2 {
  font-size: 16px;
  font-weight: 700;
}

.genie_features .genie_features_list li h2 span {
  color: $basic-icon-color;
  padding-right: 0.8rem;
}

.genie_features .genie_features_list li p {
  color: #4A5568;
}

.genie_features .genie_call_to_action {
  font-weight: 800;
  font-size: 14px;
}

.genie_features .genie_call_to_action a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: $hover-link-color;
  }

  &:focus {
    text-decoration: underline;
  }
}

.genie_features .genie_call_to_action span {
  margin-right: 0.8rem;
}

/* -------------------- */


/* Genie dropzone */

button.dz-button {
  background-color: $purple1;
  color: $gray800;
  font-size: 16px;
  border-radius: 0.52rem 0.52rem 0 0;
  border: dashed $purple500;
  border-width: 1px 1px 0 1px;
  text-align: center;
  padding: 1em 0 1.5em 0;
  width: 100%;

  &:hover {
    background: linear-gradient(#f5dafd 20%, $purple1);
  }

  &:focus {
    outline: 1px solid $purple200;
    outline-offset: -0.5rem;

    p strong {
      text-decoration: underline;
    }
  }

  p {
    margin: 0;
    padding: 0 20%;
    strong {
      color: $purple500;
    }
  }

  .genie_file_types {
    list-style-type: none;
    padding: 0;
    margin: 0 0 10px 0;
    color: $purple500;
    text-align:center;
    font-size: 20px;

    li {
      display: inline-block;
      padding-right: 0.5em;
    }
  }
}

.dz-preview {
  background-color: #fdf6fe;
  color: #1A202C;
  font-size: 1em;
  border-radius: 0;
  border: dashed $purple500;
  border-width: 0 1px 0 1px;
  padding: 0 1em 1em 1em;
}

a.dz-remove {
  font-weight: bold;
  color: $purple500;
}

.dz-image {
  display: none;
}

.dz-success-mark, .dz-error-mark {
  display: none
}

#submit-file-button {
  border-radius: 0 0 0.52rem 0.52rem;
}

/* -------------------- */

#similarity-custom-text-label {
  font-weight: bold;
  font-size: 1.125em;
}

.genie_interface textarea.genie_pastezone {
  border-color: $base-border-color;
  padding: 15px 20px;
  width: 100%;
  min-height: 140px;
  font-size: 14px;
  border-radius: 0.52rem;
  margin-bottom: 0.8rem;

  &:hover {
    border-color: $hover-input-border-color;
  }
  &:focus {
    border-color: $purple700;
  }
}

.genie_interface .genie_input .iclr4_button {
  width: 100%;
}

#uploadingPane {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(100, 100, 100, 0.8);

  > span {
    position: absolute;
    margin: auto;
    width: 100%;
    top: 40%;
    font-size: 34pt;
    text-align: center;
    color: #555;
    font-family: 'Courier New',
    monospace;
  }
}

/* Cards on the right of the home page */

.home_page_card {
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid $gray200;
  text-align: center;
  background-color: white;

  padding: 20px;

  .home-page-card-title {
    font-size: 1.8em;
    margin-bottom: 20px;
  }

  .home_page_card_image {
    margin-bottom: 20px;

    img {
      width: 40%
    }
  }

}


.home_page_card

.home_page_card p {
  margin-bottom: 2.4rem;
}

.iclr4_button.genie span {
  top: 2px;
}


/*
 * The following are from headers.css, also from Andrew Flett.
 */

.iclr_components .search {
  border: $base-border;
  border-radius: 0.64rem;
  background: #ffffff;
  display: flex;
  align-items: center;
}

.iclr_components .search form {
  display: inline-block;
}

.iclr_components .search input.query {
  padding: 0.7rem 1.6rem 0.7rem 1.2rem;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.64rem 0 0 0.64rem;
  font-size: 14px;
  width: 100%;

  &::placeholder {
    color: $gray;
  }
  &:hover {
    border-color: $hover-input-border-color;
  }
  &:focus,
  &:active {
    border-color: $focus-input-border-color;
  }
}

/* Main navigation */

.iclr_components .iclr_main_navigation {
  background: #000000;
  color: #ffffff;
  padding: 2.4rem;
  font-size: 14px;

  @media only screen and (max-width: $mobile-screen-threshold) {
    .navbar-collapse.show, .navbar-collapse.showing, .navbar-collapse.collapsing {
      background: #000000;

      max-height: calc(100vh - #{$layout-padding-with-header} + #{$basic-vertical-offset});
      overflow-y: auto;

      > * {
        * {
          margin-left: 0 !important;
          margin-right: 0 !important;

          svg {
            margin-right: 1rem !important;
          }
        }

        background: #000000;
        padding: 0.8rem 1.6rem;
        width: 100%;
        border-radius: 0.64rem;
        margin-left: 0 !important;
        margin-right: auto;
      }
    }
  }
}

.iclr_components .iclr_main_navigation .container-fluid  {
  row-gap: 1rem;
}

.iclr_components .iclr_main_navigation .navbar-toggler {
  color: #ffffff;

  &:hover {
    border: 1px solid #ffffff;
  }
}

.iclr_components .iclr_main_navigation .navbar-brand:focus {
  outline: 1px solid #ffffff;
  outline-offset: 1px;
}

.iclr_components .iclr_main_navigation .navbar-nav {
  font-family: $nav-font-family;
  font-weight: $font-weight-semibold;

  & .nav-item {
    margin-left: 8px;
  }

  & .nav-link {
    color: #ffffff;
    border-radius: 0.64rem;
    padding: 10px 16px;

    & span {
      padding-bottom: 2px;
      border-bottom: 2px solid rgba(0, 0, 0, 0);
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;

      &.iclr4-link span {
        border-color: #6C4B7C;
      }

      &.casegenie-link span {
        border-color: #204CCF;
      }

      &.products-link span {
        border-color: #8F3D0A;
      }

      &.knowledge-link span {
        border-color: #3D5F3A;
      }

      &.blog-link span {
        border-color: #225E7C;
      }

      &.about-link span {
        border-color: #A22525;
      }

      &.contact-link span {
        border-color: #A22525;
      }
    }

    // Search dropdown button
    &.nav-link-button {
      padding: 6px 16px;
      border: 1px solid #ffffff;
      margin-left: 0.4rem;
      display: flex;
      align-items: center;

      & svg {
        margin-inline-end: 1rem;
        padding-bottom: 0;
      }

      & span + span {
        margin-block-start: 0.2rem;
        padding-bottom: 0.125rem;
      }

      &:hover {
        background: #ffffff;
        color: #000000;

        &:focus {
          border: 1px solid #ffffff;
          padding: 6px 16px;
          background-color: $aluminum;
        }
      }

      &:focus {
        border-style: double;
        border-width: 4px;
        padding: 3px 13px;
      }
    }
  }
}

.iclr_components .iclr_main_navigation .dropdown-menu {
  padding: 1.44rem 2rem 1.44rem 1.28rem;
  border-radius: 0.8rem;
  border-color: transparent;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.iclr_components .dropdown-menu .dropdown-item {
  color: $nav-text-color;
  border-radius: 0.64rem;
  font-size: 14px;
  padding: 1rem 1.6rem;
  border: 1px solid transparent;

  &:focus {
    background: white;
    border-color: $focus-border-color;
    text-decoration: none;
  }

  &:hover {
    background: $hover-nav-link-color;
    text-decoration: none;
  }

  & span {
    color: $basic-icon-color;
    font-size: 18px;
    top: -1px;
    position: relative;
    padding-right: 1.2rem;
    vertical-align: middle;
  }

  & svg { vertical-align: middle }

  &.list__link {
    padding: 0.7rem 1.5rem;
    font-size: 1.4rem;

    &:hover {
      background-color: $basic-link-color;
      color: #fff;
      text-decoration: none;
    }
  }
}

.iclr_components .iclr_main_navigation .product_bar {
  margin-left: 1.6rem;
}

/* Secondary navigation */

.iclr_components .navbar.iclr_secondary_navigation {
  position: fixed;
  top: $header-height;
  left: 0;
  right:0;
  background: $nav-bar-background;
  color: $nav-text-color;
  padding: 0 1rem 0 1rem;
  font-size: 14px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06);

  & svg { font-size: 1.7rem; }
  & svg.fa-genie { font-size: 2.0rem; position: relative; top: 2px; }

  & .navbar-brand {
    padding: 0;

    &:focus {
      outline: 1px solid $focus-border-color;
      outline-offset: 1px;
    }
  }

  & .product_search {
    margin-left: 1.2rem;
    margin-right: auto;

    & input.query {
      min-width: 20rem;
    }
  }
}

.iclr_components .navbar.iclr_secondary_navigation .nav-item  {
  white-space: nowrap;

  & .iclr4_button {
    white-space: nowrap;
    min-width: 10rem;
  }
}

.iclr_components .navbar.iclr_secondary_navigation .nav-link {
  font-weight: 400;
  color: $nav-text-color;
  border-radius: 0.64rem;
  padding: 0.8rem 1.6rem;
  border: 1px solid $nav-link-border-color;

  &:hover {
    text-decoration: none;
    background: $hover-nav-link-color;
  }

  &:focus {
    text-decoration: none;
    border-color: $focus-border-color;
  }

  & svg {
    color: $basic-icon-color;
  }

  & span.purple-icon {
     color: $basic-icon-color;
     padding-right: 0.8rem;
     position: relative;
     vertical-align: middle;
   }
}

@media only screen and (max-width: $mobile-screen-threshold) {
  .iclr_components .iclr_main_navigation .navbar-nav .nav-item {
    margin-left:0;
  }

  .iclr_components .navbar.iclr_secondary_navigation .navbar-brand {
    display: none;
  }
}

.iclr_components .navbar.iclr_secondary_navigation .dropdown-menu {
  border-color: $nav-link-border-color;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  padding: 1.44rem 2rem 1.44rem 1.28rem;
  border-radius: 0.8rem;
}

.iclr_components .navbar.iclr_secondary_navigation .dropdown-menu h6 {
  font-weight: 600;
  color: #1A202C;
}

.magnifyingGlassButton {
  border: 1px solid transparent;
  font-size: 16px;
  position: relative;
  margin-left: 0.75rem;
  margin-right: 5px;
  background-color: transparent;
  cursor: pointer;
  color: $basic-icon-color;

  &:disabled  {
    pointer-events: none;
    color: $disabled-icon-color;
  }

  &:hover {
    color: $hover-icon-color;
  }

  &:focus {
    border-color: $focus-border-color;
  }
}

.notSubmitted {
  .submitButton {
    display: inline;
  }
  .loadingButton {
    display: none;
  }
}

.submitting {
  .submitButton {
    display: none;
  }
  .loadingButton {
    display: inline;
  }
}