
.search-results-container {
  .searchSnippets {
    margin-bottom: 5px;
  }
}

.search-results {


  .searchSubjectMatterBriefs, .subsequentTreatments { margin-bottom: 2px; }

  .result-treatment-container {
    margin-left: -20px;
    .neutral  { bottom: 50px; }
    .positive { bottom: 50px; }
    .negative { bottom: 50px; }
  }

  .NCIt { color: black;}
  .ICLR { color: $internal-link-color;}
  a.internalLink { color: $internal-link-color; }
  a.externalLink { color: $external-link-color; }
  a { color: black; }

  .badge { color: black; }
}


.considerations {
  display: inline-flex;
  .label {

  }
  .value {
    margin-left: 5px;
    margin-right: 3px;
  }

  .help-popover-component .help-icon-button {
    padding-inline-start: 0;

    .icon-help-circle {
      font-size: 1.025rem !important;
    }
  }
}

.result-treatment-container {
  position: absolute;
  height: 100%;
  width: 12px;
  margin-left: -20px;

  div {
    margin-top: 3px;
    height: 12px;
    width: 12px;
    border-radius: 6px;
  }

  .neutral  {
    background-color: scale-color($light-blue, $lightness: 50%);
  }
  .positive {
    background-color: scale-color($light-green, $lightness: 50%)
  }
  .negative {
    background-color: scale-color($red, $lightness: 50%);
  }
}

.treatment {
  border-bottom-width: 5px; border-bottom-style: solid;
  &.treatment-positiveconsideration { border-bottom-color: scale-color($light-green, $lightness: 50%) }
  &.treatment-neutralconsideration  { border-bottom-color: scale-color($light-blue,  $lightness: 50%) }
  &.treatment-negativeconsideration { border-bottom-color: scale-color($red, $lightness: 50%)         }
  &.treatment-subsequentcitations   { border-bottom-color: scale-color(gray, $lightness: 70%)         }
}

.searchTextSummaryText {
  white-space: pre-line;
}

.searchTextJurisageSummary {
  .jurisage-icon {
    float: left;
    padding-right: 0.5rem;
    font-size: 2.5rem;
  }
}

.searchJurisageIssuesList {
  list-style-type: disc;
  padding-inline-start: 3rem;
  padding-block-end: 0.5rem;
}

.expandedResultsHeading {
  font-weight: bold;
  margin-block-end: 0.5rem;
  margin-block-start: 1rem;

  &:first-child {
    margin-block-start: 0.5rem;
  }
}

.search-case-genie-controls {
  margin-left: -5px;
  margin-right: -5px;
  padding: 10px;
  background-color: $purple1;
  border: 1px solid $purple500;
  border-radius: 6px;
  font-size: 1.2rem;

  label, a svg+span {
    font-size: 1.2rem !important;
    margin-left: 0.6rem;
    line-height: 1.2rem;
  }


  .heading--type-4 {
    font-size: 1.3rem;
  }

  a, a:focus, a:visited { color: black; align-items: center; }
  a:hover { color: $purple500; text-decoration: none; }
  a svg, a:hover svg, a:focus svg, a:visited svg { color: $purple500; }

  #relatedIcon svg { color: $purple500; }
}

#related-icon {
  float: right;
  color: $basic-icon-color;
  font-size: 12pt;
}

#errorContainer {
  margin-inline: 1rem;
  flex-shrink: initial;
}
